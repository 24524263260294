.is-bleeding {
  @include desktop {
    margin-left: -40px;
    margin-right: -40px;
  }
}

.container.is-fluid {
  max-width: $fullhd !important;
  padding-left: $column-gap;
  padding-right: $column-gap;

  @include desktop {
    padding-left: $gap;
    padding-right: $gap;
  }
}

hr {
  background-color: $border;
  height: 1px;
  flex: none;
  margin: 24px 0 64px;
}

.tag:not(body) {
  height: 2.5em;
  padding-left: 2em;
  padding-right: 2em;

  &.is-wrapped {
    white-space: normal;
    height: auto;
    min-height: 2em;
    line-height: normal;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
  }

  &.has-ellipsis {
    display: inline-block;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.select {
  select {
    padding: 0 20px;
    background-color: $grey-lighter;
    font-weight: $weight-bold;
    border-radius: $radius-large;

    &:active,
    &:focus {
      border-color: $border;
    }
  }

  &:not(.is-multiple):not(.is-loading)::after {
    border-color: $grey;
  }
}

.block-links {
  .columns {
    padding-bottom: 30px;

    .column {
      article & {
        @include desktop {
          width: 50%;
        }
      }
    }
  }
}
