.vanilla-calendar-input-wrapper {
  // z-index: 20;

  // .dropdown-clear {
  //   z-index: 21;
  // }

  > .button {
    justify-content: flex-start;
    text-align: left;
  }

  .vanilla-calendar {
    border-radius: $radius;
    background: #4a4a4a;
    padding: 0;
    overflow: hidden;
    color: $white;
    z-index: 20;
    width: 100%;

    @include desktop {
      left: auto;
      right: 0;
      width: 360px;
    }

    .vanilla-calendar-months__month,
    .vanilla-calendar-years__year {
      font-size: $size-6;
      background: transparent;
      color: $white;

      &:hover {
        background: $grey-dark;
      }

      &:not(&_disabled) {
        border: 1px solid $danger-light;
      }
    }

    &-header {
      background: $grey-dark;
      padding: 15px 18px;
      margin-bottom: 0;
      flex-wrap: wrap;

      button {
        font-size: $size-5;
        color: inherit;

        &.vanilla-calendar-arrow:before {
          background: $white;
        }

        &:hover {
          color: $grey-light;

          &:after,
          &:before {
            background: $grey-light;
          }
        }
      }

      &__content {
        padding-left: 8px;
        justify-content: flex-start;

        .vanilla-calendar-month {
          padding-left: 0;
        }
      }

      .vanilla-calendar-week {
        margin: 16px 0 0;
        flex: none;
        width: 100%;

        &__day {
          color: #9b9b9b;
          font-size: $size-6;
        }
      }
    }

    &-wrapper {
      padding: 12px 22px 15px;

      .vanilla-calendar-day {
        &__btn {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          padding: 0;
          font-size: $size-6;
          background: transparent;
          color: inherit;
          font-weight: $weight-medium;

          &_selected {
            color: $white;
            background: $primary;
            border: none !important;
          }

          &_prev,
          &_next {
            color: #75787b;
          }

          &:not(&_selected):hover {
            background: $grey-dark;
          }

          &:not(&_disabled) {
            border: 1px solid $danger-light;
          }
        }
      }
    }
  }
}
