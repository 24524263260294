.navbar {
  @include touch {
    min-height: $navbar-height-touch;
  }

  .navbar-brand {
    flex-shrink: 1;
    align-items: center;
    position: relative;
    z-index: 30;
    min-height: $navbar-height-touch;

    @include desktop {
      z-index: auto;
    }

    .container > & {
      margin-left: 0;

      .navbar-item {
        padding-left: 0;
        line-height: 1;
        max-width: 100%;

        .logo-icon {
          color: $primary;

          svg {
            display: block;
            width: $navbar-item-img-max-height;
            height: $navbar-item-img-max-height;
          }
        }

        .logo-addon {
          color: $black;

          svg {
            height: $navbar-item-img-max-height;
          }
        }

        .logo-icon:not(:only-child) {
          @include tablet {
            padding-right: 25px;
            margin-right: 25px;
            border-right: 1px solid $black;
          }
        }

        &.is-light {
          color: $white;

          .logo-icon:not(:only-child) {
            border-color: $white;
          }

          .logo-addon {
            color: $white;
          }
        }
      }
    }
  }

  .navbar-menu {
    align-items: center;

    .container > & {
      margin-right: 0;
    }

    @include touch {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding: 0;
      z-index: 29;
      box-shadow: none;
    }

    .navbar-end {
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
      font-weight: $weight-bold;
      max-height: 95vh;
      border-radius: 0 0 $radius-large $radius-large;
      padding: $navbar-height-touch 17px 40px 17px;
      overflow: auto;

      @include touch {
        a.navbar-item,
        .navbar-link {
          &.is-arrowless {
            padding-right: 20px;
          }

          @include touch {
            color: $grey-light;

            &.is-active,
            &:hover {
              background-color: transparent !important;
              color: $white;
            }
          }
        }
      }

      @include desktop {
        padding: 10px;
        height: auto;
        border-radius: 80px;
        overflow: visible;

        > .navbar-item:not(:last-child) {
          margin-right: 4px;
        }
      }

      .navbar-dropdown {
        font-weight: $weight-medium;
        padding: 10px 0px 10px 20px;

        @include desktop {
          padding: 10px 0;
        }

        .navbar-item {
          padding: 10px 28px 11px;

          @include touch {
            font-size: $size-4;
          }

          &:not(:hover) {
            color: #d9d9d9;
          }
        }
      }

      .navbar-link {
        display: flex;
        align-items: center;

        .icon {
          color: $white;
          font-size: 10px;
        }
      }

      .navbar-item {
        @include touch {
          font-size: $size-2;
        }

        &.is-rounded {
          // width: 40px;
          padding-left: 16px;
          padding-right: 16px;
          background: $grey-dark;
        }

        &.has-dropdown {
          @include touch {
            &:not(.is-active) .navbar-dropdown {
              display: none;
            }
          }
        }
      }

      .block-social_links {
        .button {
          color: $grey-light;
          font-size: 42px;

          &:hover {
            color: $grey-lighter;
          }
        }
      }
    }
  }

  .navbar-mobile {
    margin-left: auto;
    height: 100%;
    background: rgba(47, 45, 52, 0.9);
    // backdrop-filter: blur(20px);
    border-radius: $radius-large;
    display: flex;
    padding: 10px;
    z-index: 99;

    .navbar-item {
      height: 40px;
      width: 40px;
      justify-content: center;
      padding: 0;

      &.navbar-search {
        background: $white;
        color: $grey-dark;
      }
    }

    .navbar-burger {
      &,
      &:hover,
      &:focus {
        background-color: $primary;
      }

      span {
        border-radius: 2px;
        background: $white;
        height: 2px;
        width: 50%;
        left: 25%;

        &.is-small {
          width: 30%;
          left: 35%;
        }

        &:nth-child(1) {
          top: calc(50% - 8px);
        }

        &:nth-child(2) {
          top: calc(50% - 1px);
        }

        &:nth-child(3) {
          top: calc(50% + 6px);
        }
      }

      &.is-active {
        span {
          &.is-small {
            left: 25%;
            width: 50%;
          }

          &:nth-child(1) {
            transform: translateY(7px) rotate(45deg);
          }

          &:nth-child(3) {
            transform: translateY(-7px) rotate(-45deg);
          }
        }
      }
    }
  }
}

a.navbar-item,
.navbar-link {
  border-radius: 215px;
  padding: 10px 28px 11px;
  line-height: normal;
}
