.button {
  font-weight: $weight-bold;

  &.is-small {
    height: 40px;
  }

  &.is-large {
    padding-left: 3em;
    padding-right: 3em;
    // font-size: $size-4;

    @include desktop {
      height: 60px;
    }
  }

  &.has-icon-bg {
    background: transparent;
    border: 0;
    color: $black;
    font-weight: $weight-medium;

    &:hover {
      .icon {
        background: $grey-dark;
      }
    }

    .icon {
      color: $white;
      background: $grey-light;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 100%;
    }
  }

  &.is-transparent {
    border: 0;
    background: transparent;
    padding: 0;
    height: auto;
  }
}

#js-tothetop {
  position: fixed;
  right: 25px;
  bottom: -60px;
  width: 60px;
  height: 60px;
  z-index: 9;
  transition: bottom 0.2s ease-out;

  &.is-visible {
    bottom: 25px;
  }
}
