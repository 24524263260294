header.header-main {
  margin-bottom: 20px;
  position: relative;

  @include tablet {
    margin-bottom: 40px;
  }

  .image {
    margin-left: -20px;
    margin-right: -20px;

    img {
      border-radius: 0 0 $radius-large $radius-large;
    }

    @include touch {
      margin-top: calc(-1 * $navbar-height);
      height: 430px;
    }

    @include desktop {
      img {
        border-radius: $radius-large;
      }
    }
  }

  &.is-full {
    @include desktop {
      margin-bottom: 60px;
    }

    .image {
      margin-top: calc(-1 * $navbar-height);

      img {
        @include tablet {
          border-radius: 0;
        }
      }

      @include desktop {
        margin-left: calc(-1 * $gap);
        margin-right: calc(-1 * $gap);
      }

      @include fullhd {
        img {
          border-radius: 0 0 $radius-large $radius-large;
        }
      }
    }
  }

  .box {
    &.is-left {
      max-width: 680px;
      width: 100%;
      margin: -100px auto 0;

      @include tablet {
        position: absolute;
        margin: 0;
        bottom: 20px;
      }

      @include desktop {
        bottom: 80px;
      }
    }
  }

  section {
    .is-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      @include tablet {
        left: $column-gap;
      }

      .box.is-dark {
        margin-bottom: $column-gap;

        @include tablet {
          margin-bottom: 40px;
        }
      }
    }

    @include tablet {
      &.is-offset-left > .is-bottom {
        margin-left: -40px;

        .box {
          h1.title {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .boxes {
    align-items: flex-end;
    justify-content: flex-end;

    .box {
      margin-bottom: -50px;

      @include desktop {
        margin-bottom: -60px;
      }
    }
  }
}
