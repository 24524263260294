@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$weight-black: 900;

a {
}

h1 {
  &.title {
    font-weight: $weight-black;
    font-size: 2.5rem;
    line-height: 1.175;

    @include desktop {
      font-size: 4rem;
      line-height: 1.125;
      margin-left: 36px;
    }
  }
}

// set line-heights corresponding to sizes (+ responsive)
@for $i from 1 through length($line-heights) {
  $lh: nth($line-heights, $i);

  .is-size-#{$i} {
    line-height: #{$lh};
  }

  @include mobile {
    .is-size-#{$i}-mobile {
      line-height: #{$lh};
    }
  }

  @include tablet {
    .is-size-#{$i}-tablet {
      line-height: #{$lh};
    }
  }

  @include touch {
    .is-size-#{$i}-touch {
      line-height: #{$lh};
    }
  }

  @include desktop {
    .is-size-#{$i}-desktop {
      line-height: #{$lh};
    }
  }

  @include widescreen {
    .is-size-#{$i}-widescreen {
      line-height: #{$lh};
    }
  }

  @include fullhd {
    .is-size-#{$i}-fullhd {
      line-height: #{$lh};
    }
  }
}
