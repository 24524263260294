footer.footer {
  border-top: 1px solid $border;
  margin-top: 80px;

  .block-social_links {
    .button {
      color: $grey;
      font-size: $size-1;

      @include desktop {
        font-size: 30px;
      }

      &:hover {
        color: $grey-dark;
      }
    }
  }

  .logo svg {
    height: $navbar-item-img-max-height;
    width: $navbar-item-img-max-height;
  }

  .columns {
    .column > a {
      display: block;
      color: $grey-dark;
      line-height: 1.25;

      &:hover {
        color: $primary;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
