.template-page {
  aside {
    .aside-container {
      @include tablet {
        min-height: 100%;
        z-index: 1;
        left: $column-gap;
        right: 66.66%;
        position: absolute;
        max-width: 320px;
        padding-right: $column-gap;
      }

      @include desktop {
        left: $gap;
      }

      .menu {
        transition: opacity 0.25s;
        background: $background;
        border-radius: $radius;
        opacity: 1;

        .menu-list {
          a {
            border-radius: 0;
            border-bottom: 1px solid transparent;
            transition: color 0.15s, border-color 0.5s;

            &.is-active {
              border-bottom: 1px solid $border;
            }
          }
        }

        &.is-transparent {
          opacity: 0;
          pointer-events: none;
        }
      }

      .is-sticky {
        position: sticky;
        top: 40px;
      }
    }

    &.is-offset-top {
      .aside-container {
        .box {
          margin-top: -100px;

          @include tablet {
            margin-top: -50%;
          }
        }
      }
    }

    .block-contact_cards {
      .box {
        background: transparent;
        border-radius: 0;
        margin-bottom: 40px;

        .title {
          font-size: $size-4;
          color: $primary;
          border-bottom: 1px solid $border;
          padding-bottom: 20px;
        }

        p {
          padding-left: 20px;
        }
      }
    }

    .box {
      .box-info-date {
        margin-bottom: 26px;
        padding-right: 56px;

        @include tablet {
          padding-right: 0;
          margin-bottom: 80px;
        }
      }

      .calendar-link {
        position: absolute;
        top: 22px;
        right: 22px;
        line-height: 1;
        width: auto;

        @include tablet {
          top: 32px;
          right: 32px;
          // bottom: 38px;

          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }

  .is-offset-left {
    @include tablet {
      padding-left: $column-gap;
      margin-left: 33.33%;
    }
  }

  .select {
    position: sticky;
    top: 20px;
    z-index: 9;
    margin-top: -10px;
  }

  article {
    position: relative;
    font-size: $size-5;
    color: $grey-dark;
    margin-top: 30px;

    @include tablet {
      margin-top: 0;
    }

    @include desktop {
      font-size: $size-4;
    }

    h1.title {
      font-weight: $weight-normal;
      margin-left: 0;
      margin-bottom: 30px;
    }

    section {
      position: relative;

      &:first-child {
        > [class^='block-'] {
          margin-top: 0;
        }
      }

      &:last-child {
        > [class^='block-'] {
          margin-bottom: 0;
        }

        .block-carousel {
          @include tablet {
            margin-bottom: 160px;
          }
        }
      }

      .block-carousel {
        margin-top: 2.5em;
        margin-bottom: 2.1666666667em;

        @include mobile {
          margin-left: -20px;
          margin-right: -20px;
        }

        .carousel {
          position: relative;
          z-index: 2;

          .carousel-container {
            .flickity-viewport {
              background-color: $background-dark;

              @include tablet {
                border-radius: $radius-large;
                overflow: hidden;
              }
            }

            .carousel-item {
              // background: $grey-light;

              img {
                width: 100%;
                height: 320px;
                object-fit: cover;
                transition: all 0.6s;
              }

              &:not(.is-selected) {
                img {
                  filter: grayscale(1) brightness(0.9);
                  opacity: 0.5;
                }
              }

              @include tablet {
                margin-right: 20px;

                img {
                  height: 860px;
                  max-height: 60vh;
                  width: auto;
                  max-width: none;
                  object-fit: initial;
                }
              }

              .caption {
                display: none;
              }
            }
          }
        }
      }

      .block-image_block {
        margin-top: 2.1666666667em;
        margin-bottom: 2.3333333333em;

        .caption {
          color: #595959;
          line-height: 1.125;
          font-size: $size-6;
          margin-top: 20px;
          padding-left: 56px;

          @include tablet {
            padding-left: 0;
            max-width: 240px;
          }
        }
      }

      .block-heading_block {
        margin-top: 3.75em;
        margin-bottom: 1.6666666667em;
      }

      .block-subtitle_block {
        margin-top: 3.125em;
        margin-bottom: 1em;
      }

      .block-paragraph_block {
        margin-top: 1em;
        margin-bottom: 1em;

        p:not(:last-child) {
          margin-bottom: 1em;
        }
      }

      .block-block_quote {
        margin-top: 2.1666666667em;
        margin-bottom: 2.0833333333em;

        p {
          font-size: 1.75em;
          font-style: italic;
          font-weight: $weight-light;
          line-height: 1.1904761905;
        }

        footer {
          @extend .is-size-5;
        }
      }

      .block-documents {
        margin-top: 1.6666666667em;
        margin-bottom: 1.6666666667em;

        .button {
          padding-left: 30px;
          padding-right: 30px;
          justify-content: flex-start;
          height: 60px;

          span {
            font-weight: $weight-normal;
            overflow: hidden;
            text-overflow: ellipsis;

            @include tablet {
              font-weight: $weight-bold;
            }
          }

          .icon {
            flex-shrink: 0;
            color: $primary;
            font-size: 30px;

            @include tablet {
              font-size: $size-3;
            }
          }

          &:hover {
            background-color: $primary;
            color: $white;

            .icon {
              color: $white;
            }
          }
        }
      }

      .block-persons {
        margin-top: 1.6666666667em;
        margin-bottom: 1.5em;

        .box {
          display: flex;
          align-items: center;
          border-radius: $radius-rounded;
          background-color: $white;
          font-size: 20px;
          line-height: 1.2727272727;
          padding: 10px;

          @include tablet {
            font-size: 22px;
            padding: 20px;
          }

          .image {
            flex: none;
            width: 120px;
            height: 120px;

            img {
              border-radius: $radius-rounded;
            }
          }
        }
      }

      .block-q_a {
        margin-top: 1em;
        margin-bottom: 1em;

        li {
          position: relative;

          &:first-child {
            margin-bottom: 1em;
          }

          label {
            top: 0;
            line-height: 1;
            position: absolute;
            right: 100%;
            padding-right: 40px;
            font-size: 1.75em;
          }
        }
      }

      .block-logo_grid {
        margin-top: 2.1666666667em;
        margin-bottom: 2.0833333333em;

        .box {
          img {
            filter: grayscale(100%);
            display: block;
            height: 80px;
            transition: filter 0.25s;
            object-fit: contain;
          }

          &:hover {
            img {
              filter: grayscale(0);
            }
          }
        }
      }

      .block-columns {
        margin-top: 1em;
        margin-bottom: 1em;
      }

      .block-links {
        margin-top: 2.125em;
        margin-bottom: 1em;
      }

      .block-q_a_accordion {
        .accordion-body {
          display: none;
        }

        > .button {
          justify-content: space-between;
          // align-items: flex-start;
          min-height: 80px;
          height: auto;
          font-size: 1em;
          padding: 15px 41px 15px 28px;
          white-space: normal;
          text-align: left;

          &:not(:first-child) {
            margin-top: 10px;
          }

          .icon {
            transform: rotate(180deg);
            color: $grey;
            flex: none;
          }

          &.is-active {
            background: $white;

            span.is-question,
            .icon {
              transform: rotate(0);
              color: $red;
            }

            & + .accordion-body {
              display: block;
            }
          }

          &:hover:not(.is-active) {
            background: $background-dark;
          }
        }

        .block-text {
          padding: 32px 55px 32px 32px;
          background: $white;
          margin-top: 10px;
          border-radius: $radius;

          &:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            margin-top: -5px;
          }
        }
      }

      .block-contact_cards {
        .box {
          padding: 36px 40px 36px 36px;
          height: 100%;

          .title {
            word-break: normal;
            font-size: $size-2;
          }
        }
      }

      .block-embed_block {
        .image {
          background: $background-dark;
        }

        .image > * {
          @extend .is-overlay;
          position: absolute !important;
          height: 100% !important;
          width: 100% !important;
        }
      }

      .block-pages_links {
        margin-top: 1.66667em;
        margin-bottom: 1.66667em;
      }
    }
  }
}
