.grid-container {
  &:not(.is-hidden) + & {
    margin-top: 80px;
  }

  .grid {
    position: relative;

    .button {
      display: block;
      margin: 0 auto;
    }

    &:after {
      @include loader;
      pointer-events: none;
      border: 2px solid $grey-dark;
      border-right-color: transparent;
      border-top-color: transparent;
      position: absolute;
      left: calc(50% - 1rem);
      width: 2rem;
      height: 2rem;
      bottom: 0.5rem;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &.is-loading {
      min-height: 4rem;

      &:after {
        opacity: 1;
        transition-timing-function: ease-out;
      }
    }
  }

  .filters .dropdown {
    width: 100%;

    .dropdown-trigger {
      width: 100%;
    }
  }
}
