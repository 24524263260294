.dropdown {
  width: 100%;

  .dropdown-trigger {
    width: 100%;

    .button {
      min-width: 200px;
      width: 100%;

      .counter {
        display: none;
        flex: none;
        padding: 0;
        width: 22px;
        line-height: 22px;
        height: 22px;
        margin-left: 12px;
      }

      .icon:last-child {
        transition: transform 0.2s;
        margin-left: auto !important;
        margin-right: 0;
        font-size: 10px;
      }
    }
  }

  .dropdown-clear {
    padding: 0;
    width: 30px;
    height: 30px;
    font-size: $size-8;
    position: absolute;
    right: -15px;
    top: -15px;
  }

  .dropdown-menu {
    min-width: 100%;

    .dropdown-item {
      font-size: $size-8;
      padding: 5px 10px;

      .checkbox {
        width: 100%;

        input {
          display: none;
        }

        .button {
          text-align: left;
          justify-content: space-between;
          border: 0;
          font-weight: $weight-bold;
          min-height: 2.5em;
          height: auto;
          padding: 0 10px;
          line-height: normal;
          width: 100%;
          // white-space: wrap;

          .icon {
            margin: 0 0 0 8px;
            visibility: hidden;
          }
        }

        input:checked + .button {
          background: $grey-lighter;
          color: $grey-dark;

          &:hover .icon {
            visibility: visible;
          }
        }
      }
    }
  }

  &.is-set,
  &.is-active,
  &:hover {
    .dropdown-trigger {
      .button {
        background: $grey-dark;
        color: $white;
      }
    }
  }

  &.is-active {
    .dropdown-trigger .button .icon:last-child {
      transform: rotateX(180deg);
    }
  }

  &.is-set {
    .dropdown-trigger .button {
      .counter {
        display: block;
      }
    }
  }
}
