$grey-blur: rgba(47, 45, 52, 0.6);

.box {
  overflow: hidden;
  isolation: isolate;
  position: relative;

  .tag {
    font-weight: $weight-bold;
  }

  &.is-proiect {
    min-height: 380px;
    display: flex;

    @include desktop {
      min-height: 520px;
    }

    .image {
      position: absolute !important;
      height: 100%;
      width: 100%;
    }

    .box-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 4;
      color: $white;
      padding: 50px 40px;

      header {
        flex-grow: 1;
      }
    }

    article & {
      @include desktop {
        min-height: 280px;
        flex-direction: row-reverse;
        align-items: stretch;

        .image {
          position: relative !important;
          width: 60%;
          height: auto;

          &:after {
            display: none;
          }
        }

        .box-info {
          width: 40%;
          color: $black;
          background: $background-dark;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding: 40px;

          .title {
            font-size: $size-3 !important;
            margin-bottom: 0;
          }

          p:not(.title) {
            display: none;
          }
        }
      }
    }
  }

  &.is-image-box {
    display: flex;
    min-height: 190px;

    .image {
      position: absolute;

      &.has-gradient:after {
        @extend %overlay;
        transition: opacity 1s;
        z-index: 1;
        content: '';
        display: block;
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
      }
    }

    .image-overlay {
      position: absolute;
      z-index: 4;
      padding: 30px 14px 30px 36px;
      top: 0;
      left: 0;
      right: 0;
      height: 180px;
      color: $white;
      line-height: normal;

      @include desktop {
        height: 100%;
        width: 200px;
      }
    }

    .box-info {
      flex: 1;
      z-index: 5;
      position: relative;
      transition: color 0.5s;
      margin-top: 180px;
      padding: 24px 36px;

      &:after {
        content: '';
        @extend %overlay;
        z-index: -1;
        display: block;
        background: $white;
        transition: left 0.25s;
      }

      .tag {
        position: absolute;
        top: -50px;
      }

      @include desktop {
        overflow: hidden;
        padding: 30px 36px 24px;
        margin-left: 200px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .title {
          flex: 1;
        }

        .tag {
          position: static;
        }
      }
    }

    &:hover {
      .image.has-gradient {
        &:after {
          opacity: 0;
        }
      }

      .image-overlay {
        .tag {
          background: $grey-dark;
          color: $white;
        }
      }

      .box-info {
        color: $white;
        transition-duration: 1.5s;

        .box-date {
          color: $white !important;
        }

        &:after {
          opacity: 0;

          @include desktop {
            opacity: 1;
            left: 100%;
          }
        }
      }
    }

    article & {
      .box-info {
        @include desktop {
          margin-left: 45%;
        }
      }
    }
  }

  &.is-articol {
    padding: 35px 60px 35px 40px;
    transition: background-color 0.3s;

    header {
      height: 38px;
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      span {
        line-height: 1;
      }
    }

    .tag {
      background-color: $grey-lighter;
      transition: all 0.15s;
    }

    &:hover {
      background-color: $background-dark;

      .tag {
        background-color: $grey-dark;
        color: $white;
      }
    }
  }

  &.is-link {
    padding: 30px 40px 45px;
    font-weight: $weight-medium;
    overflow: visible;
    background: $background-dark;

    @include tablet {
      padding: 35px 55px 45px;
      background: $white;
    }

    .button {
      position: absolute;
      bottom: -30px;

      @include desktop {
        transition: transform 0.3s;
        transform: scale(0);

        .icon {
          transform: scale(0);
          transition: transform 0.3s;
          transition-delay: 0.2s;
        }
      }
    }

    &:hover {
      background: $background-dark;

      .button {
        transform: scale(1);

        .icon {
          transform: scale(1);
        }
      }
    }
  }

  &.is-noutate {
    display: flex;
    flex-direction: column;
    padding: 40px 40px 50px;
    transition: background-color 0.3s;
    background-color: $background-dark;
    min-height: 400px;

    header {
      flex: 1;
      margin-bottom: 16px;
    }

    .box-info {
    }

    &:hover {
      background-color: $white;

      .tag {
        background-color: $grey-dark;
        color: $white;
      }
    }

    @include desktop {
      min-height: 520px;
    }
  }

  &.is-aside {
    padding: 45px 36px 36px 42px;
    border: 1px solid $primary;
    background: transparent;
  }

  &.is-header {
    display: flex;
    flex-direction: column;

    .box-info {
      padding: 28px 40px 32px;
      flex: 1;
    }

    .button {
      border-radius: 0;
      height: 60px;
      text-align: left;
      justify-content: flex-start;
      padding-left: 40px;

      @include tablet {
        padding-left: 20px;
        text-align: center;
        justify-content: center;
      }
    }
  }

  &.is-dark {
    background: $grey-blur;
    backdrop-filter: blur(15px);
    padding: 24px 20px 30px;
    color: $white;

    .button {
      max-width: 100%;
    }

    @include desktop {
      padding: 32px 40px 40px;
    }
  }

  .image {
    &.has-overlay {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        display: block;
        z-index: 3;
        left: 0;
        right: 0;
      }

      &.is-top:after {
        top: 0;
        bottom: 50%;
        background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
      }

      &.is-bottom:after {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
        top: 0;
        bottom: 0;

        @include desktop {
          top: 30%;
        }
      }
    }
  }

  &.is-dummy {
    background: $background-dark;
  }
}

a.box {
  .image {
    overflow: hidden;

    img {
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.25, 1);
    }

    &:before {
      content: '';
      @extend %overlay;
      transition: opacity 0.35s;
      background: $grey-blur;
      backdrop-filter: blur(5px);

      opacity: 0;
    }
  }

  &:hover {
    // background: $background-dark;

    .image {
      &:before {
        opacity: 1;
        z-index: 4;
      }

      img {
        transition-duration: 2s;
        transform: scale(1.1);
      }
    }
  }
}
