.carousel {
  button {
    box-shadow: none !important;
  }

  .carousel-container {
    .flickity-prev-next-button {
      border-radius: $radius-rounded;
      background: $grey-dark;
      color: $white;
      width: 60px;
      height: 60px;
      padding: $column-gap;

      .flickity-button-icon {
        position: static;
        width: 100%;
        height: 100%;
      }

      &:hover {
        background-color: $primary;
      }

      &.next {
        left: 100px;
      }

      &.previous {
        left: $column-gap;
      }

      @include mobile {
        transform: none;
        top: 100%;
        margin-top: 20px;
      }

      @include tablet {
        margin-right: 0;
        position: absolute;
        transform: translateY(-50%);

        &.next {
          left: auto;
          right: 15px;
        }

        &.previous {
          left: 15px;
        }
      }

      @include desktop {
        &.next {
          right: -30px;
        }

        &.previous {
          left: -30px;
        }
      }
    }

    &.is-fullscreen {
      z-index: 10;
    }
  }

  .flickity-page-dot {
    width: 15px;
    height: 15px;
    background: rgba(255, 255, 255, 0.5);

    &.is-selected {
      background: $white;
    }
  }

  .oh-caption {
    pointer-events: none;
    position: relative;

    .is-counter {
      position: absolute;
      right: $column-gap;
      top: 28px;

      @include tablet {
        position: static;
      }
    }

    @include tablet {
      position: absolute;
      top: 100%;
      left: 0;
      width: 33.33%;
      max-width: 240px;
      overflow: hidden;
    }

    .wrapper {
      padding-left: $column-gap;
      padding-top: 108px;

      @include tablet {
        padding-top: 2.1666666667em;
        padding-left: 40px;
      }
    }
  }

  &.is-default {
    img {
      // cursor: pointer;
    }
  }
}
